// @ts-nocheck
import Statsig from 'statsig-js';
import { BaseTransformer } from '../base-transformer';
import type { IAdapter, InitArguments } from '../event-client';
import type { EventPayloads, EventTypes, LoginPayload } from '../events';

const onlineStoreSubscriptions: Array<EventTypes> = [
	'session-start',
	'page-view',
	'product-description-click',
	'add-to-cart',
	'remove-from-cart',
	'filter',
	'page-load',
	'page-dom-interactive',
	'proceed-to-checkout',
	'purchase',
	'checkout-page-landing',
	'checkout-payment-method-select',
	'checkout-gift-card-applied',
	'checkout-place-order-click',
	'checkout-thankyou',
	'checkout-return-from-shipping',
	'checkout-errors',
	'card-payment-success',
	'card-payment-failure',
	'wishlist-add-product',
	'wishlist-remove-product',
	'wishlist-create',
	'wishlist-delete',
	'homepage-pagination',
	'user-login',
	'navbar-click',
	'brand-click',
	'collection-click',
	'order-cancelled',
	'order-tracking-clicked',
	'filter-applied',
	'product-search',
	'category-clicked',
	'user-login-failed',
	'user-otp-sent',
	'cod-verification-success',
	'homepage-carousel-click',
	'checkout-rewards-enabled',
	'rewards-register-click',
	'shipping-rate-error',
	'tax-rate-error',
	'checkout-validation-failed',
	'app-download-banner',
	'address-validation-opened',
	'validated-address-order',
	'tax-shipping-validated',
	'validating-tax-shipping',
	'rewards-toggle-clicked',
	'place-order-response',
	'search-node-click',
	'search-suggestion-click',
	'node-button-clicked',
	'coupon-validated',
	'coupon-removed',
	'coupon-error',
	'view-size-chart',
	'selected-variant',
	'delivery-options-saved',
	'multiple-shipping-experiment',
	'multiple-shipping-selection',
	'sending-eid-gift',
];

const sellersAppSubscriptions: Array<EventTypes> = [
	'pa-session-start',
	'pa-page-view',
	'pa-pagination',
	'pa-filter',
	'pa-add-new-product',
	'pa-cancel-add-new-product',
	'pa-page-load',
	'pa-page-dom-interactive',
	'pa-export-click',
	'pa-product-click',
	'pa-vendor-dropdown',
	'pa-brand-dropdown',
	'pa-drop-dropdown',
	'pa-additional-column-selection',
	'pa-additional-column-deselection',
	'pa-product-creation-time-taken',
	'pa-add-drop',
	'pa-product-creation-edit',
	'pa-product-submit-for-review',
	'pa-product-creation-cancel',
	'pa-plp-search',
	'pa-plp-tabs',
	'pa-approve-reject-tabs',
	'pa-approve-reject-search',
	'pa-approve-reject-detail',
	'pa-rejected-product-resubmit',
	'pa-drop-detail',
	'pa-drop-edit',
	'pa-drop-search',
	'pa-drop-brand-search',
	'pa-drop-creation',
	'pa-drop-proudct-add',

	'pa-visit-olp',
	'pa-visit-odp',
	'pa-click-tab-olp',
	'pa-click-sort-olp',
	'pa-search-order-number-olp',
	'pa-click-filter',
	'pa-click-export-csv-olp',
	'pa-click-order-row-olp',
	'pa-click-edit-delivery-date',
	'pa-click-change-delivery-date',
	'pa-click-interact-cancellation-banner',
	'pa-click-status-pill',
	'pa-click-more-columns',
	'pa-click-calendar-icon',
	'pa-click-chevron-delivery-date',

	'pa-logistics-request-creation',
	'pa-logistics-pickup-status',
	'pa-logistics-closing-status',
	'pa-logistics-filters-usage',
	'pa-logistics-po-detail-view',
	'pa-logistics-request-notification',
	'pa-logistics-search',
	'pa-logistics-sort',
	'pa-logistics-bulk-update',

	'pa-sizechart-delete',
	'pa-sizechart-create',

	// VAO events start
	'pa-registration-visit',
	'pa-homepage-visit',
	'pa-laam-academy-create-product',
	'pa-laam-academy-packaging-shipment',
	'pa-laam-academy-inventory-handover',
	'pa-laam-academy-track-rider-stock',
	'pa-laam-academy-order-mgmt',
	'pa-getStarted-create-sizechart',
	'pa-getStarted-create-product',
	'pa-getStarted-create-drop',

	// VAO evenets end

	// #region Analytics
	'pa-analytics-tabs',
	// #endregion

	// #region product duplicate
	'pa-product-duplication-initiated',
	'pa-product-duplication-completed',
	// #endregion

	// #region sizechart duplicate
	'pa-sizechart-duplication-initiated',
	'pa-sizechart-duplication-completed',
	// #endregion

	// #region SEO
	'pa-seo-tags-added',
	'pa-seo-tags-updated',
	// #endregion
];

const cmsAppSubscriptions: Array<EventTypes> = [
	'cms-component-insert',
	'cms-page-preview',
	'cms-page-save',
	'cms-component-ai-generate',
	'cms-entity-pin',
	'cms-ai-filter-select',
	'cms-meta-tags-add',
	'cms-hide-component',
	'cms-component-duplicate',
	'cms-page-publish',
	'cms-session-start',
];

const subscribedEvents = [
	...onlineStoreSubscriptions,
	...sellersAppSubscriptions,
	...cmsAppSubscriptions,
];

const TransformerMap = {
	'add-to-cart': BaseTransformer.transformAddToCartEvent,
	'remove-from-cart': BaseTransformer.transformRemoveFromCartEvent,
	'proceed-to-checkout': BaseTransformer.transformProceedToCheckoutEvent,
	'wishlist-add-product': BaseTransformer.transformAddToWishlistEvent,
	purchase: BaseTransformer.transformPurchaseEvent,
};

export class StatsigAdapter implements IAdapter {
	countryCode = null;
	name = 'statsig';
	async init(arg: InitArguments['statsig']): Promise<void> {
		try {
			await Statsig.initialize(
				arg.sdkKey,
				{
					...arg.user,
					country: arg.countryCode!,
					custom: this.getCustomAttributes(),
				},
				{
					environment: {
						tier: arg.environment,
					},
				},
			);
			window.$statsig = Statsig;
			this.countryCode = arg.countryCode;
		} catch (e) {
			console.log(e);
		}
	}

	sendEvent<T extends EventTypes>(
		eventName: T,
		eventData: EventPayloads[T],
	): void {
		if (!subscribedEvents.includes(eventName)) {
			return;
		}
		if (!window.$statsig) {
			throw new Error(
				'Statsig not found in sendEvent! Please initialize Statsig first.',
			);
		}
		// @ts-ignore
		const { data, name } = TransformerMap[eventName]
			? // @ts-ignore
				TransformerMap[eventName](eventData)
			: { data: eventData, name: eventName };
		// @ts-ignore
		window.$statsig!.logEvent(
			name ?? eventName,
			null,
			data as unknown as Record<string, string>,
		);
	}

	login(data: LoginPayload): void {
		if (!window.$statsig || !data.userId) {
			return;
		}
		window.$statsig!.updateUser({
			country: this.countryCode,
			userID: data.userId!,
			email: data.email,
			custom: this.getCustomAttributes(),
		});
	}

	getCustomAttributes() {
		return {
			environment: 'laam',
			domain: window.location.hostname,
			isApp: localStorage.getItem('isApp') ? true : false,
		};
	}

	logout(): void {
		// @ts-ignore
		if (!window.$statsig) {
			return;
		}
		// @ts-ignore
		window.$statsig.updateUser({
			custom: this.getCustomAttributes(),
		});
	}
}
