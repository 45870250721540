<script setup lang="ts">
import { StatsigSingleton } from '@laam/statsig-client';
import {
	newArrivalPageExperimentInjectionKey,
	newArrivalPLPExperimentInjectionKey,
	newArrivalsFilterExperimentInjectionKey,
	type NewArrivalsFilterExperiment,
	type NewArrivalsPageExperiment,
	type NewArrivalsPLPExperiment,
} from '~/utils/use-statsig-experiment';

const newArrivalsPLPExperiment = ref<NewArrivalsPLPExperiment>();
const deliveryMethodsExperiment = ref<boolean>(false);
const newArrivalPageExperiment = ref<NewArrivalsPageExperiment>();
const newArrivalsFilterExperiment = ref<NewArrivalsFilterExperiment>();

onMounted(async () => {
	newArrivalsPLPExperiment.value = StatsigSingleton.getExperiment(
		'new-arrival-plp',
	).value as NewArrivalsPLPExperiment;
	deliveryMethodsExperiment.value = StatsigSingleton.getExperiment(
		'delivery-methods',
	).get('Allowed', false);
	newArrivalPageExperiment.value = StatsigSingleton.getExperiment(
		'new-arrivals-page',
	).value as NewArrivalsPageExperiment;
	newArrivalsFilterExperiment.value = StatsigSingleton.getExperiment(
		'new-filter-plp',
	).value as NewArrivalsFilterExperiment;
});

provide(newArrivalPLPExperimentInjectionKey, newArrivalsPLPExperiment);
provide(deliveryMethodsExperimentInjectionKey, deliveryMethodsExperiment);
provide(newArrivalPageExperimentInjectionKey, newArrivalPageExperiment);
provide(newArrivalsFilterExperimentInjectionKey, newArrivalsFilterExperiment);
</script>
<template>
	<slot />
</template>
