import type { InjectionKey } from 'vue';

export type NewArrivalsPLPExperiment = {
	new_arrival_period: string;
	is_active: boolean;
};

export type NewArrivalsPageExperiment = {
	is_active: boolean;
	min_sellable_products: number;
	brand_created_at: string;
	drop_created_at: string;
};

export type NewArrivalsFilterExperiment = {
	is_active: boolean;
	period: string;
};

export const newArrivalPLPExperimentInjectionKey = Symbol(
	'newArrivalPLPExperiment',
) as InjectionKey<Ref<NewArrivalsPLPExperiment | undefined>>;
export const deliveryMethodsExperimentInjectionKey = Symbol(
	'deliveryMethodsExperiment',
) as InjectionKey<Ref<boolean | undefined>>;
export const newArrivalPageExperimentInjectionKey = Symbol(
	'newArrivalPageExperiment',
) as InjectionKey<Ref<NewArrivalsPageExperiment | undefined>>;
export const newArrivalsFilterExperimentInjectionKey = Symbol(
	'newArrivalsFilterExperiment',
) as InjectionKey<Ref<NewArrivalsFilterExperiment | undefined>>;

export const useStatsigExperiment = () => {
	const newArrivalPLPExperiment = inject(newArrivalPLPExperimentInjectionKey);
	const deliveryMethodsExperiment = inject(
		deliveryMethodsExperimentInjectionKey,
	);
	const newArrivalPageExperiment = inject(newArrivalPageExperimentInjectionKey);
	const newArrivalsFilterExperiment = inject(
		newArrivalsFilterExperimentInjectionKey,
	);
	return {
		newArrivalPLPExperiment,
		deliveryMethodsExperiment,
		newArrivalPageExperiment,
		newArrivalsFilterExperiment,
	};
};
